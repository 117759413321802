<template>
  <div>
    <Header />
    <div class="colored-body">
      <router-view />
      <!-- <div class="bottom-tip" v-if="user !== 'ban'">
        <div class="content" v-if="pageVerify && pageVerify.length">
          {{
            `Номер одобрения: ${pageVerify[0].number} Дата одобрения: ${this.$m(
              pageVerify[0].approval_date
            ).format("DD.MM.YYYY")}. Дата истечения: ${this.$m(
              pageVerify[0].expire_date
            ).format("DD.MM.YYYY")}`
          }}
        </div>
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/main/Header.vue";
import Footer from "../components/Footer.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
export default {
  components: {
    Header,
    Footer,
  },
  name: "education",
  data: () => ({}),
  computed: {
    ...mapGetters(["user", "pageVerify"]),
  },
  methods: {
    ...mapActions(["getSession", "fetchPageVerify"]),
  },

  async mounted() {
    await this.fetchPageVerify(this.$route.path);
    // if (!Object.keys(this.user).length) {
    //   await this.getSession();
    // }
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.fetchPageVerify(this.$route.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.colored-body {
  padding-top: 1px;
}
.footer {
  z-index: 100;
}

.header {
  z-index: 100001;
}

.content {
  z-index: 1000;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  @media screen and (max-width: 1220px) {
    background-color: #f8f8f8;
    z-index: 99999999999;
  }
}

.header {
  background-color: #fff;
}

.bottom-tip {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b2b4b4;

  @media screen and (max-width: 1220px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
</style>

<style>
</style>